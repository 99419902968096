<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">固件管理 ({{ infor.typeName }})</div>
        <div>
          <el-button
            v-if="chuangjian"
            type="primary"
            @click="addIdType(0, null)"
            class="addIdType"
            >+ 上传固件</el-button
          >
          <el-button
            v-if="chuangjian"
            type="primary"
            @click="addIdType1"
            class="addIdType1"
            >返回上一级</el-button
          >
        </div>
      </div>
    </div>

    <div class="hl-content">
      <el-table
        class="hl-table"
        :data="tableData"
        height="90%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
      >
        <el-table-column label="版本号">
          <template slot-scope="scope">
            <span>{{ scope.row.versionNo }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="固件上传时间">
          <template slot-scope="scope">
            <span>{{ scope.row.uploadTime }}</span>
          </template>
        </el-table-column> -->
        <el-table-column label="初始偏移量">
          <template slot-scope="scope">
            <span>{{ scope.row.offset }}</span>
          </template>
        </el-table-column>
        <el-table-column label="文件名称">
          <template slot-scope="scope">
            <span>{{ scope.row.fileName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="固件路径">
          <template slot-scope="scope">
            <span>{{ scope.row.fileUrl }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong">
                <el-button
                  style="color: red"
                  type="text"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
    <addModify ref="addModify" />
    <!-- <disableModify ref="disableModify" /> -->
  </div>
</template>
<script>
// import { querySelectAllUser } from "@/api/api2.js";
import { getRequest, postRequest, deleteRequest } from "@/api/api.js";
import addModify from "@/views/versions/addModify.vue";
// import disableModify from "@/views/roomState/disableModify.vue";
export default {
  data() {
    return {
      tableData: [],
      list: [],
      hotelList: [],
      input: "",
      status: 0,
      versionCode: "",
      versionName: "",
      statusRemark: "",
      totolNums: 2,
      infor: {},
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      zbgl: false,
      deleteVisible: false,
      gj: false,
      row: {},
      todelete: "",
    };
  },
  components: {
    addModify,
    // disableModify
  },
  created() {
    if (!this.power.checkedpower("0304016")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // 创建
    this.chuangjian = this.power.checkedpower("0304014");
    // 修改
    // 删除，禁用
    this.shanchu = this.power.checkedpower("0304015");
    this.infor = this.$route.query;
    this.getFounderList();
  },
  methods: {
    // 获取创始人列表
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      getRequest("selectAllVersions?roomTypeId=" + this.infor.id).then(
        (res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            // res.data.forEach((item) => {
            //   item.uploadTime = item.uploadTime.format("YYYY-MM-DD hh:mm:ss");
            // });
            this.tableData = res.data;
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        }
      );
    },
    getVersions(row) {
      // console.log(row.id);
      this.$router.push({
        name: "Versions",
        query: row.id,
      });
    },
    // 删除
    del(row) {
      this.deleteVisible = true;
      this.todelete = "";
      this.row = row;
    },
    deleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      // let parms = {
      //   id: this.row.id,
      // };
      deleteRequest("deleteHotelTypeRoomVersion?id=" + this.row.id).then(
        (res) => {
          this.$nextTick(() => {
            loadingInstance.close();
          });
          if (res.status == 200) {
            this.$message({
              duration: 5000,
              message: "删除成功!",
              type: "success",
            });
            this.deleteVisible = false;
            this.getFounderList();
          } else {
            this.$message({
              duration: 5000,
              message: res.message,
              center: true,
            });
          }
        }
      );
    },
    search() {},
    setTemplate(row) {
      this.$router.push({
        name: "setTemplate",
        query: row,
      });
    },
    // 新增
    addIdType() {
      let state = "创建";
      this.$refs.addModify.show(state);
    },
    // 修改
    edit(index, row) {
      let state = "编辑";
      this.$refs.addModify.show(state, row, index);
    },
    // 每页的数据数
    onPaginationSize(Sizeval) {
      this.condition.num = Sizeval;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(Indexpage) {
      this.condition.offset = Indexpage;
      this.getFounderList();
    },
    addIdType1() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.block {
  position: absolute;
  bottom: 2px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.el-card {
  position: relative;
}
.zt > div {
  display: flex;
  align-items: center;
}
.addIdType2 {
  width: 100px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.dialog-footer > .el-button {
  width: 60px;
  height: 30px;
}
.addIdType1 {
  width: 136px;
  height: 40px;
  color: #387dff;
  background: #d7e4fc;
  border-radius: 4px;
}
</style>
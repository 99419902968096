<template>
  <div>
    <el-dialog
      title="上传固件"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <el-form-item label="初始偏移量" prop="offset">
            <el-input
              placeholder="请输入初始偏移量"
              v-model="formInline.offset"
              style="width: 100%"
            ></el-input>
          </el-form-item>
          <div class="upLoadFile">
            <div @click="upLoadFile" class="upLoadFile">
              <input
                type="file"
                ref="fileInput"
                @change="readFile"
                style="display: none"
              />
              <div v-if="allName != null && allName != ''">
                <div>文件：</div>
                {{ allName }}
              </div>
              <el-button
                v-else
                class="choose"
                slot="trigger"
                size="small"
                type="primary"
                >选取文件</el-button
              >
            </div>
          </div>
          <div v-if="wjFalse" class="wj">(例:SmartHotel_Test_V1.3.bin)</div>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="createStorage('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { createInsertUser, updateInsertUser } from "@/api/api2.js";
import { getRequest, postRequest } from "@/api/api.js";
import global from "../../Global.vue";
import axios from "axios";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      hotel: true,
      index: null,
      fileList: [],
      name: null,
      name2: null,
      name3: null,
      wjFalse: false,
      //表单
      formInline: {},
      allName: null,
      file: {},
      //表单验证
      rules: {
        offset: [
          {
            required: true,
            message: "请输入初始偏移量",
            trigger: "blur",
          },
        ],
        file: [
          {
            required: true,
            message: "请选择文件",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
      this.formInline.statusRemark = "";
      this.formInline.versionCode = "";
    },
    show(item, val, index) {
      this.wjFalse = false;
      this.showing = true;
      this.formInline = {};
      this.bedisabled = false;
    },
    upLoadFile() {
      this.$refs.fileInput.click();
    },
    readFile(e) {
      let file = e.target.files[0];
      this.file = e.target.files[0];
      // console.log(1, e.target.files[0].name);
      this.allName = e.target.files[0].name;
      if (file == null) {
        return;
      }
      // let name = "." + file.name.split(".")[1];
      let name2 = file.name.substring(file.name.length - 4, file.name.length);
      // this.name = "." + file.name.split(".")[1];
      this.name2 = file.name.substring(file.name.length - 4, file.name.length);
      this.name3 = file.name;
      // console.log(111,name2)
      // return
      if (!name2.match(/.bin/i)) {
        this.wjFalse = true;
        this.$message({
          duration: 5000,
          message: "文件格式错误! ",
          type: "error",
          hasClose: true,
        });
        return;
      }
    },
    createStorage(formData) {
      this.$refs[formData].validate((valid) => {
        if (valid) {
          if (this.formInline.offset < 8000000) {
            this.$message({
              duration: 5000,
              message: "固件上传初始偏移量必须大于或等于8000000!",
              type: "error",
              time: 2000,
              hasClose: true,
            });
            return;
          }
          if (this.name2) {
            if (!this.name2.match(/.bin/i)) {
              this.wjFalse = true;
              this.$message({
                duration: 5000,
                message: "文件格式错误!",
                type: "error",
                hasClose: true,
              });
              return;
            }
          } else {
            this.$message({
              duration: 5000,
              message: "请选择文件!",
              type: "error",
              hasClose: true,
            });
            return;
          }
          let param = new FormData(); // 创建form对象
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
              token: window.sessionStorage.getItem("token"),
              Authorization: window.sessionStorage.getItem("token"),
            },
          };
          console.log(2323, this.name3.includes("V"));
          if (!this.name3.includes("V")) {
            this.$message({
              duration: 5000,
              message: "文件名格式错误!",
              type: "error",
            });
            this.wjFalse = true;
            return;
          }
          let versionNo = this.name3.split("V");
          let versionNo2 = versionNo[1];
          let versionNo3 = versionNo2.split("b");
          let versionNo4 = versionNo3[0];
          let versionNo5 = versionNo4.substring(0, versionNo4.length - 1);
          console.log(11112, versionNo5);
          // return;
          param.append("versionNo", versionNo5);
          param.append("file", this.file);
          param.append("roomTypeId", this.$route.query.id);
          param.append("offset", this.formInline.offset);
          console.log(param);
          // return;
          axios.post(global.upload, param, config).then((res) => {
            if (res.data.status == 200) {
              // that.hotelInfo.logo = global.imgurl + res.data.data;
              // console.log(that.hotelInfo.logo);
              this.$message({
                duration: 5000,
                message: "创建成功!",
                type: "success",
              });
              this.hide();
              this.$parent.getFounderList();
            } else {
            }
          });
        } else {
          this.$message({
            duration: 5000,
            message: "请仔细核对填写的内容！",
            type: "warning",
          });
        }
      });
    },
    // 编辑
    updateStation() {
      postRequest("/updateHotelType", this.formInline).then((res) => {
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "编辑成功!",
            type: "success",
          });
          this.hide();
          this.$refs.formInline.resetFields(); //清空表单
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "warning",
          });
        }
      });
    },
    //附件
    //上传附件----------------------------------------------
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      let isFlag = true;
      if (!isLt5M) {
        this.$message.error("上传头像文件大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //--------------------------------------------------------------------
    // 成功之后的返回
    handleAvatarSuccess(res, file) {
      this.fileJson.push(res.data);
      this.fileListUpload.push(res.data);
    },

    // 删除之后的返回值
    handleRemove(file, fileList) {
      this.fileJson.forEach((fileId, index) => {
        if (fileId.id == file.id) {
          this.fileJson.splice(index, 1);
          this.fileListUpload.splice(index, 1);
        }
      });
    },
    //预览
    handlePreview(file) {
      let fileName = file.fileName;
      //获取最后一个.的位置
      let index = fileName.lastIndexOf(".");
      //获取后缀
      let ext = fileName.substr(index + 1);
      if (this.isAssetTypeAnImage(ext)) {
        //图片预览
        window.open(
          window.string.BUSINESS_PUBLIC_API_URL +
            "/sys/attachments/getImageByStream/?id=" +
            file.id
        );
      } else {
        //永中预览
        previewUpload(file.id).then(({ data, result, message }) => {
          if (result == 1) {
            var previewUrl = data.filePath;
            window.open(window.string.PUBLIC_VIEW_FILE_API + previewUrl);
          } else {
            this.$message({
              duration: 5000,
              message: message,
              type: "warning",
            });
          }
        });
      }
    },
    isAssetTypeAnImage(ext) {
      return (
        ["png", "jpg", "jpeg", "bmp", "gif"].indexOf(ext.toLowerCase()) !== -1
      );
    },
    handleDowload(file) {
      window.location.href =
        window.string.BUSINESS_PUBLIC_API_URL +
        "/sys/attachments/donwload/?id=" +
        file.id;
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 320px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.upLoadFile {
  width: 100px;
  margin-bottom: 10px;
  /* height: 100px; */
  /* line-height: 100px; */
  /* background: #1471fe; */
}
.wj {
  color: red;
}
.img {
  width: 100px;
  height: 100px;
}
.add {
  border: 1px dashed #cdd1d6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}
.upImg {
  /* background-color: aquamarine; */
  height: 150px;
  display: flex;
  align-items: center;
}
.choose {
  width: 100px;
  margin-top: 0;
}
/* input {
  height: 100px;
  background-color: #387dff;
} */
</style>